<template>
  <v-autocomplete
    v-model="selected"
    :label="labelClean"
    :items="items"
    :loading="loading"
    item-text="person.last_name"
    item-value="pk"
    :return-object="returnObject"
  >
    <template #item="{ item }">
      <PersonName :person="item.person" phonebook />
      <v-spacer />
      {{ item.relation }}
    </template>
    <template #selection="{ item }">
      <PersonName :person="item.person" phonebook />
      <span class="pl-2">({{ item.relation }})</span>
    </template>
  </v-autocomplete>
</template>

<script>
import PersonName from "@/components/blocks/PersonName";
import axios from "axios";
import { personPhoneBookName } from "@/lib/person";

export default {
  name: "RelatedPerOrgSelector",
  components: { PersonName },
  props: {
    label: { default: "", type: String },
    value: { required: false }, // selected person - either an object or pk - depends on returnObject
    returnObject: { default: false, type: Boolean },
    perOrg: { required: true, type: Object },
  },

  data() {
    return {
      allPerOrgs: [],
      loading: false,
      selected: null,
    };
  },

  computed: {
    labelClean() {
      return this.label ? this.label : this.$t("label.person");
    },
    items() {
      return this.allPerOrgs;
    },
    url() {
      return `/api/organization/${this.perOrg.organization.pk}/person/`;
    },
  },

  methods: {
    async fetchPerOrgs() {
      try {
        this.loading = true;
        let resp = await axios.get(this.url);
        this.allPerOrgs = resp.data;
        this.allPerOrgs.forEach(
          (item) => (item.person.name = personPhoneBookName(item.person))
        );
        this.allPerOrgs = this.allPerOrgs
          .filter((item) => item.pk !== this.perOrg.pk)
          .sort((a, b) => a.person.name.localeCompare(b.person.name));
      } catch (error) {
        console.error("Error fetching persons: ", error);
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.fetchPerOrgs();
  },

  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      this.selected = this.value;
    },
  },
};
</script>

<style scoped></style>
