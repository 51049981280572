<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-card-title>{{ $t("label.addMate") }}</v-card-title>
      <v-card-text>
        <PerOrgPerOrgLinkForm
          :per-org="perOrg"
          :link-id="linkId"
          :person="person"
          @save="$emit('save')"
          ref="form"
        >
          <template #buttons>
            <v-btn color="secondary" @click="show = false">{{
              $t("button.cancel")
            }}</v-btn>
          </template>
        </PerOrgPerOrgLinkForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PerOrgPerOrgLinkForm from "@/components/forms/PerOrgPerOrgLinkForm";

export default {
  name: "EditPerOrgPerOrgLinkDialog",

  components: { PerOrgPerOrgLinkForm },

  props: {
    value: { required: true, type: Boolean },
    perOrg: { required: true, type: Object },
    linkId: { required: false, type: Number },
    person: { required: true, type: Object },
  },

  data() {
    return {
      show: this.value,
    };
  },

  watch: {
    show() {
      this.$emit("input", this.show);
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    value() {
      this.show = this.value;
    },
  },
};
</script>

<style scoped></style>
