<template>
  <v-container>
    <v-row>
      <v-col class="pb-0">
        <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn icon @click="showQrCode = true" class="mt-2">
          <v-icon>mdi-qrcode</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card class="pa-md-4 pb-6" v-if="person">
          <v-card-text>
            <section>
              <div class="icons float-right">
                <GhostButton
                  type="edit"
                  @click="showEditDialog = true"
                  v-if="can('change', 'person')"
                />
                <AddTagButton
                  color="ghost"
                  :object-id="personId"
                  object-type="person"
                  @tag-added="$refs.taglist.fetchTags()"
                  v-if="can('add', 'taggeditem')"
                />
              </div>

              <h2 class="text-h4 pb-4">
                <v-icon color="#bbbbbb" class="mr-3" large
                  >mdi mdi-account</v-icon
                >
                <PersonName :person="person" />

                <TagList
                  object-type="person"
                  :object-id="personId"
                  class="ml-4"
                  ref="taglist"
                />
              </h2>

              <table class="overview">
                <tr v-if="person.birth_date">
                  <th class="field-name" v-text="$t('label.birthDate')"></th>
                  <td>{{ birthDateString }}</td>
                </tr>

                <tr v-if="person.reg_number">
                  <th>{{ $t("label.regNum") }}</th>
                  <td>{{ person.reg_number }}</td>
                </tr>
                <tr v-if="hasAddress">
                  <th>{{ $t("label.address") }}</th>
                  <td>
                    <AddressWidget :address="person.address" separate-zip />
                  </td>
                </tr>

                <tr v-if="person.public_notes">
                  <th class="field-name">{{ $t("label.publicNotes") }}</th>
                  <td>{{ person.public_notes }}</td>
                </tr>
                <tr v-if="person.internal_notes">
                  <th class="field-name">{{ $t("label.internalNotes") }}</th>
                  <td>{{ person.internal_notes }}</td>
                </tr>
              </table>
            </section>

            <section class="pt-8">
              <div class="icons float-right">
                <GhostButton
                  type="contacts"
                  @click="editContacts(null)"
                  v-if="can('change', 'personcontact')"
                />
              </div>

              <h3 class="text-h6">{{ $t("label.personalContacts") }}</h3>
              <ul class="no-bullet">
                <li
                  v-for="(contact, index) in person.contacts"
                  :key="`contact-${index}`"
                  class="py-2"
                >
                  <ContactWidget
                    :contact="contact"
                    show-notes
                    show-suggestions
                    emulate-icon
                  />
                </li>
              </ul>
            </section>

            <section
              v-for="(orglink, index) in person.org_links"
              class="pt-8"
              :key="`org-${index}`"
            >
              <OrganizationParentsBreadcrumb :org="orglink.organization" />
              <h3 class="text-h6">
                <div class="icons float-right">
                  <GhostButton
                    type="contacts"
                    @click="editContacts(orglink)"
                    v-if="can('change', 'perorgcontact')"
                  />
                  <AddTagButton
                    color="ghost"
                    :object-id="orglink.pk"
                    object-type="perorglink"
                    @tag-added="
                      $refs[`perorgtaglist-${orglink.pk}`][0].fetchTags()
                    "
                    v-if="can('add', 'taggeditem')"
                  />
                  <GhostButton
                    type="addPerson"
                    :tooltip="$t('label.addMate')"
                    @click="
                      editedPerOrgLink = orglink;
                      showPerOrgPerOrgLinkDialog = true;
                    "
                    v-if="can('add', 'perorgperorglink')"
                  />
                  <GhostButton
                    type="editLink"
                    @click="updateLink(orglink)"
                    v-if="can('change', 'perorglink')"
                  />
                  <GhostButton
                    type="unlink"
                    two-step
                    @click="unlink(orglink)"
                    v-if="can('delete', 'perorglink')"
                  />
                </div>

                <router-link
                  :to="{
                    name: 'organizationDetail',
                    params: { organizationId: orglink.organization.pk },
                  }"
                  class="subdued"
                  tag="a"
                  >{{ orglink.organization.name }}</router-link
                >
                <OrganizationIcon :org="orglink.organization" class="mx-1" />
                <span class="role">&ndash; </span>
                <span class="role">{{ orglink.relation }}</span>

                <TagList
                  object-type="perorglink"
                  :object-id="orglink.pk"
                  class="ml-4"
                  :ref="`perorgtaglist-${orglink.pk}`"
                />
              </h3>

              <!-- contacts related to this organization -->
              <ul class="no-bullet">
                <li
                  v-for="contact in orglink.contacts"
                  :key="`org-c-${index}-${contact.pk}`"
                  class="py-2"
                >
                  <ContactWidget
                    :contact="contact"
                    show-notes
                    show-suggestions
                  />
                </li>
              </ul>

              <!-- mates - primary and secondary related persons -->
              <ul class="no-bullet">
                <li
                  v-for="mate in orglink.secondary_people"
                  :key="`mates-${mate.pk}`"
                  class="py-2"
                >
                  <div class="icons float-right">
                    <GhostButton
                      type="unlink"
                      two-step
                      @click="unlinkMate(mate)"
                      v-if="can('delete', 'perorgperorglink')"
                    />
                  </div>
                  <PersonIcon :person="mate.secondary.person" color="ghost" />
                  <PersonNameLink :person="mate.secondary.person" />
                  <span class="role">&ndash; </span>
                  <span class="role">{{ mate.rel_type.secondary_ref }}</span>
                </li>
                <li
                  v-for="mate in orglink.primary_people"
                  :key="`mates-${mate.pk}`"
                  class="py-2"
                >
                  <div class="icons float-right">
                    <GhostButton
                      type="unlink"
                      two-step
                      @click="unlinkMate(mate)"
                      v-if="can('delete', 'perorgperorglink')"
                    />
                  </div>
                  <PersonIcon :person="mate.primary.person" color="ghost" />
                  <PersonNameLink :person="mate.primary.person" />
                  <span class="role">&ndash; </span>
                  <span class="role">{{ mate.rel_type.primary_ref }}</span>
                </li>
              </ul>
            </section>
          </v-card-text>
        </v-card>
        <v-sheet v-else class="pt-8">
          <v-skeleton-loader loading type="heading" class="pb-4" />
          <v-skeleton-loader loading type="paragraph" />
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-btn
          color="primary"
          @click="showPerOrgLinkDialog = true"
          v-if="can('add', 'perorglink')"
          :disabled="!person"
          >{{ $t("button.addOrgLink") }}</v-btn
        >
      </v-col>
    </v-row>
    <EditPersonDialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      :person="person"
      @save="
        fetchPerson();
        showEditDialog = false;
      "
    />
    <EditContactsDialog
      v-if="person && showContactEditDialog"
      v-model="showContactEditDialog"
      :object-type="editedPerOrgLink ? 'perorglink' : 'person'"
      :object-id="editedPerOrgLink ? editedPerOrgLink.pk : personId"
      :contacts="editedPerOrgLink ? editedPerOrgLink.contacts : person.contacts"
      @save="
        fetchPerson();
        showContactEditDialog = false;
        editedPerOrgLink = null;
      "
    />
    <EditPerOrgLinkDialog
      v-if="person && showPerOrgLinkDialog"
      v-model="showPerOrgLinkDialog"
      :person="person"
      @save="
        fetchPerson();
        showPerOrgLinkDialog = false;
        editedPerOrgLink = null;
      "
      :link-id="editedPerOrgLink ? editedPerOrgLink.pk : null"
      :organization="editedPerOrgLink ? editedPerOrgLink.organization : null"
      :relation="editedPerOrgLink ? editedPerOrgLink.rel_type : null"
      edited-side="organization"
    />
    <EditPerOrgPerOrgLinkDialog
      v-if="person && editedPerOrgLink && showPerOrgPerOrgLinkDialog"
      :per-org="editedPerOrgLink"
      :person="person"
      v-model="showPerOrgPerOrgLinkDialog"
      @save="
        fetchPerson();
        showPerOrgPerOrgLinkDialog = false;
      "
    />
    <v-dialog v-model="showQrCode" v-if="showQrCode" max-width="360">
      <v-card>
        <v-card-text class="text-center pa-4 pt-8">
          <qr-code :value="vCardText" v-if="vCardText" size="280" />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="showQrCode = false">{{ $t("button.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import OrganizationIcon from "@/components/blocks/OrganizationIcon";
import axios from "axios";
import { preprocessPerson } from "@/lib/person";
import EditPersonDialog from "@/components/dialogs/EditPersonDialog";
import AddTagButton from "@/components/blocks/AddTagButton";
import ContactWidget from "@/components/blocks/ContactWidget";
import GhostButton from "@/components/icons/GhostButton";
import TagList from "@/components/TagList";
import EditContactsDialog from "@/components/dialogs/EditContactsDialog";
import EditPerOrgLinkDialog from "@/components/dialogs/EditPerOrgLinkDialog";
import { mapGetters } from "vuex";
import PersonNameLink from "@/components/blocks/PersonNameLink";
import PersonIcon from "@/components/blocks/PersonIcon";
import EditPerOrgPerOrgLinkDialog from "@/components/dialogs/EditPerOrgPerOrgLinkDialog";
import QrCode from "qrcode.vue";
import vcard from "vcards-js";
import PersonName from "@/components/blocks/PersonName";
import OrganizationParentsBreadcrumb from "@/components/blocks/OrganizationParentsBreadcrumb";
import { format } from "date-fns";
import { cs } from "date-fns/locale";
import AddressWidget from "@/components/blocks/AddressWidget";

export default {
  name: "PersonDetailPage",

  components: {
    AddressWidget,
    OrganizationParentsBreadcrumb,
    PersonName,
    EditPerOrgPerOrgLinkDialog,
    PersonIcon,
    PersonNameLink,
    EditPerOrgLinkDialog,
    EditContactsDialog,
    TagList,
    GhostButton,
    ContactWidget,
    AddTagButton,
    EditPersonDialog,
    OrganizationIcon,
    QrCode,
  },

  props: {
    personId: { required: true, type: Number },
  },

  data() {
    return {
      person: null,
      showEditDialog: false,
      showContactEditDialog: false,
      showPerOrgLinkDialog: false,
      editedPerOrgLink: null,
      showPerOrgPerOrgLinkDialog: false,
      selectedContact: null,
      showQrCode: false,
    };
  },

  computed: {
    ...mapGetters(["can"]),
    breadcrumbs() {
      return [
        { text: this.$t("label.home"), to: "/" },
        {
          text: this.$t("label.people"),
          to: { name: "Home", query: { tab: "person" } },
        },
        {
          text: this.person
            ? this.person.phonebook_name_
            : this.$t("label.person"),
        },
      ];
    },
    vCardText() {
      if (this.person) {
        let card = new vcard();
        card.version = "3.0";
        card.firstName = this.person.first_name;
        card.lastName = this.person.last_name;
        card.namePrefix = this.person.title_before;
        card.nameSuffix = this.person.title_after;
        if (this.person.birth_date) {
          card.birthday = this.birthDate;
        }
        if (this.person.middle_name) {
          card.middleName = this.person.middle_name;
        }
        card.email = this.person.contacts
          .filter((c) => c.contact_type.category.alias === "email")
          .map((c) => c.value);
        card.cellPhone = this.person.contacts
          .filter((c) => c.contact_type.category.alias === "phone")
          .map((c) => c.value);
        card.note = this.person.public_notes;
        if (this.person.org_links.length) {
          let perorg = this.person.org_links[0];
          card.organization = perorg.organization.name;
          card.title = perorg.relation;
          card.workEmail = perorg.contacts
            .filter((c) => c.contact_type.category.alias === "email")
            .map((c) => c.value);
          card.workPhone = perorg.contacts
            .filter((c) => c.contact_type.category.alias === "phone")
            .map((c) => c.value);
        }
        return card.getFormattedString();
      }
      return null;
    },
    birthDate() {
      if (this.person?.birth_date) {
        return new Date(this.person.birth_date);
      }
      return null;
    },
    birthDateString() {
      if (this.birthDate) {
        return format(this.birthDate, "PP", { locale: cs });
      }
      return null;
    },
    hasAddress() {
      return Object.keys(this.person.address).length > 0;
    },
  },

  methods: {
    async fetchPerson() {
      let resp = await axios.get(`/api/person-detailed/${this.personId}/`);
      this.person = preprocessPerson(resp.data);
    },
    async unlink(orglink) {
      await axios.delete(`/api/perorglink/${orglink.pk}/`);
      await this.fetchPerson();
    },
    async updateLink(perorglink) {
      this.editedPerOrgLink = perorglink;
      this.showPerOrgLinkDialog = true;
    },
    async unlinkMate(link) {
      await axios.delete(`/api/perorg-perorg-link/${link.pk}/`);
      await this.fetchPerson();
    },
    editContacts(orglink) {
      this.editedPerOrgLink = orglink;
      this.showContactEditDialog = true;
    },
  },

  mounted() {
    this.fetchPerson();
  },

  watch: {
    personId() {
      this.person = null;
      this.fetchPerson();
    },
  },
};
</script>
