<template>
  <v-select
    v-model="selected"
    :label="labelClean"
    :items="roles"
    :loading="loading"
    item-text="text"
    item-value="pk"
    return-object
  >
    <template #item="{ item }">
      <v-icon small class="pr-1">mdi-arrow-left</v-icon>
      <span>{{ item.primary_ref }}</span>
      <v-spacer></v-spacer>
      <span>{{ item.secondary_ref }}</span>
      <v-icon small class="pl-1">mdi-arrow-right</v-icon>
    </template>
    <template #selection="{ item }">
      <v-icon small class="pr-1">mdi-arrow-left</v-icon>
      <span>{{ item.primary_ref }}</span>
      <v-spacer></v-spacer>
      <span>{{ item.secondary_ref }}</span>
      <v-icon small class="pl-1">mdi-arrow-right</v-icon>
    </template>
  </v-select>
</template>

<script>
import axios from "axios";

export default {
  name: "PerOrgPerOrgRoleSelector",

  props: {
    label: { default: "", type: String },
    value: { required: false, type: Object }, // selected organization
  },

  data() {
    return {
      roles: [],
      loading: false,
      selected: null,
    };
  },

  methods: {
    async fetchRelationships() {
      try {
        this.loading = true;
        let resp = await axios.get("/api/perorg-perorg-relationship/");
        this.roles = [];
        let roles = resp.data;
        roles.forEach((item) => {
          let forward = { ...item };
          forward.text = `${forward.primary_ref} - ${forward.secondary_ref}`;
          forward.backward = false;
          let back = { ...item };
          back.primary_ref = item.secondary_ref;
          back.secondary_ref = item.primary_ref;
          back.text = `${back.primary_ref} - ${back.secondary_ref}`;
          back.backward = true;
          back.pk = -item.pk;
          this.roles.push(forward);
          this.roles.push(back);
        });
        this.roles.sort((a, b) => a.text.localeCompare(b.text));
      } catch (error) {
        console.error("Error fetching roles: ", error);
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.selected = null;
      this.fetchRelationships();
    },
  },

  computed: {
    labelClean() {
      return this.label ? this.label : this.$t("label.role");
    },
  },

  mounted() {
    this.fetchRelationships();
  },

  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      this.selected = this.value;
    },
  },
};
</script>

<style scoped></style>
