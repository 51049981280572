<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col v-if="person" cols="auto" class="align-self-center">
          <PersonName :person="person" />
        </v-col>
        <v-col>
          <PerOrgPerOrgRoleSelector v-model="role" ref="roleSelector" />
        </v-col>
        <v-col>
          <RelatedPerOrgSelector v-model="otherPk" :per-org="perOrg" />
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <slot name="buttons"></slot>
        </v-col>
        <v-col cols="auto" color="primary">
          <v-btn @click="save()" :disabled="invalid" color="primary">{{
            $t("label.connect")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import RelatedPerOrgSelector from "@/components/blocks/RelatedPerOrgSelector";
import PerOrgPerOrgRoleSelector from "@/components/blocks/PerOrgPerOrgRoleSelector";
import PersonName from "@/components/blocks/PersonName";

export default {
  name: "PerOrgPerOrgLinkForm",

  components: {
    PersonName,
    PerOrgPerOrgRoleSelector,
    RelatedPerOrgSelector,
  },

  props: {
    linkId: { required: false, type: Number },
    perOrg: { required: true, type: Object },
    person: { required: false, type: Object },
  },

  data() {
    return {
      otherPk: null,
      role: null,
      pk: this.linkId,
    };
  },

  computed: {
    invalid() {
      return !(this.role && this.otherPk);
    },
  },

  methods: {
    async save() {
      let reverse = this.role.pk < 0;
      let data = {
        primary: reverse ? this.otherPk : this.perOrg.pk,
        secondary: reverse ? this.perOrg.pk : this.otherPk,
        rel_type: Math.abs(this.role.pk),
      };
      if (this.pk) {
        await axios.patch(`/api/perorg-perorg-link/${this.pk}/`, data);
      } else {
        let resp = await axios.post(`/api/perorg-perorg-link/`, data);
        this.pk = resp.data.pk;
      }
      this.$emit("save");
    },
    reset() {
      this.role = null;
      this.pk = this.linkId;
      this.otherPk = null;
      if (this.$refs.roleSelector) {
        this.$refs.roleSelector.reset();
      }
    },
  },
};
</script>

<style scoped></style>
